<!--
   /**
      * newsDetails.vue
      * @module {src/modules/public}
      * @desc 前端新闻详情
      * @author shiqiu
      * @date 2021-01-21
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row style="margin-top: 20px">
              <el-col :span="24" v-html="content">
              </el-col>
              <el-col :span="24" style="margin-top: 40px;margin-bottom: 20px">
                <p style="text-align: center">
                  <el-link style="margin-right: 10px" target="_blank" href="https://system.rich-f.cn/#/login">
                <el-button type="success">同意</el-button>
                  </el-link>
                <el-button type="warning" @click="toGoBack">不同意</el-button>
                </p>
              </el-col>
            </el-row>
          </div>
        </div>
          </el-col>

        </el-row>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "agreement",
  // props: ['news_code'],
  components:{},
  data() {
    return {
      content:`
      <div style="font-size: 16px;line-height: 30px"><p><strong>重要提示：</strong><br>
请您仔细阅读以下条款，并确认您已完全理解本协议之规定，尤其是免除及限制责任的条款、知识产权条款、法律适用及争议解决条款。</p>
<p>若您对本声明或本协议任何条款有异议，请停止注册或使用富融（https://www.rich-f.cn/，包括手机、电脑等移动设备客户端，下同）所提供的全部服务。</p>
<p><strong>一、协议的接受、变更与补充</strong><br>
1、勾选本协议前选项框并点击“注册”，将视为您签署了本协议，表明您自愿接受本协议全部条款的约束，本协议将构成您与上海佰集科技有限公司及其经营的“富融”平台（以下统称“富融”，包括相关关联方）之间具有约束力的法律文件。无论您是进入富融浏览网页，还是在富融上发布任何内容，或者是直接或通过各类方式（如站外API引用等）间接使用富融服务和数据的行为，都将被视作已无条件接受本声明所涉全部内容。</p>
<p>2、富融有权利对本协议进行修改，协议修改后，富融将通过在相关页面公告或发送通知等方式公布修改的内容，修改后的协议一经公布即有效的代替原协议。如果您不同意本协议的修改，请立即停止访问或使用本网站或取消已经获得的服务；如果您选择继续访问或使用本网站，则视为您已接受本协议的修改。</p>
<p>3、签署的本协议所列明的条款，并不能完全涵盖您与富融之间所有的权利和义务。因此，富融不定期公布的其他声明、规则等均视为本协议之补充协议，为本协议不可分割的组成部分，与本协议具有同等法律效力。</p>
<p>4、如本协议与富融平台其它协议条款不一致，以其它协议条款内容为准。</p>
<p><strong>二、帐号密码与注册、登录</strong><br>
1、帐号的取得<br>
(1)您确认，在您开始注册富融前，已经具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</p>
<p>(2)您注册成功后，即成为富融注册用户，用户须对在富融的注册信息的真实性、合法性、有效性承担全部责任。您可自行创建、修改昵称，但用户名和昵称的命名及使用应遵守相关法律法规并符合网络道德，不得冒充他人或恶意注册使人误认；不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；用户名和昵称中不能含有任何侮辱、诽谤、淫秽或暴力等侵害他人合法权益或违反公序良俗的词语。如您违反前述规定，富融有权随时限制或拒绝您使用该账号，甚至注销该账号。<br>
(3)用户名的管理<br>
1)请勿以党和国家领导人或其他名人的真实姓名、字、号、艺名、笔名、头衔等注册和使用昵称（如确为本人，需要提交相关证据并通过审核方可允许使用）；<br>
2)请勿以国家组织机构或其他组织机构的名称等注册和使用昵称（如确为该机构，需要提交相关证据并通过审核方可允许使用）；<br>
3)请勿注册和使用与其他网友相同、相仿的用户名或昵称；<br>
4)请勿注册和使用不文明、不健康的用户名和昵称；<br>
5)请勿注册和使用易产生歧义、引起他人误解或带有各种奇形怪状符号的用户名和昵称。<br>
6)用户以虚假信息骗取账号名称注册，或账号头像、简介等注册信息存在违法和不良信息的，富融将暂停或注销。</p>
<p>2、请您妥善保管您注册时填写的用户帐号和密码，不要将帐号密码告知他人，因用户原因导致帐号或密码泄露而造成的法律后果由用户负责。同时，用户还应当对以此帐号登录进行的所有活动和事件承担全部后果与法律责任。</p>
<p>3、若您的帐号或密码遗失，可以通过注册信息按照相关步骤找回密码，若您发现账号遭到未授权的使用或存在其他安全漏洞的情况，应立即告知富融。</p>
<p><strong>三、用户个人信息</strong><br>
<strong>1、个人信息的保护</strong><br>
(1)保护用户个人信息及个人隐私是富融的基本原则之一。</p>
<p>(2)您在注册帐号或使用富融服务的过程中，需要填写一些必要的信息。若国家法律法规有特殊规定的，您可能还需要填写真实的身份信息。若您填写的信息不完整，可能无法注册帐户，或在使用富融服务过程中受到限制。</p>
<p>(3)我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息，但出于安全性和身份识别（如找回密码服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。</p>
<p>(4)富融将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</p>
<p>(5)未经您的同意，富融不会向富融以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。</p>
<p>(6)富融非常重视对未成年人个人信息的保护，若未成年人（尤其是十岁以下）希望得以使用富融提供的服务，必须得到父母（监护人）的同意。</p>
<p>(7)富融将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，保证不对外公开或向第三方披露或提供用户注册资料及用户在使用网络服务时存储在富融平台、服务器或数据库的非公开内容和信息，但下列情况除外：<br>
1)  事先获得用户的授权；<br>
2)  根据有关的法律法规要求；<br>
3)  按照相关政府主管部门和司法机关的要求；<br>
4)  为维护社会公众的利益；<br>
5)  为维护富融的合法权益。</p>
<p><strong>2、个人信息的收集</strong><br>富融在提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。<br>
(1)   您提供的信息</p>
<ol>
<li>您在注册帐户或使用富融的服务时，向我们提供的相关个人信息，包括但不限于真实姓名、证件信息、电话号码、地址、电子邮件等；</li>
<li>您通过富融的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。</li>
</ol>
<p>(2)我们获取的您的信息<br>
您使用富融服务时我们可能收集如下信息：使用富融服务时，系统可能通过cookies、web beacon或其他方式自动采集的技术信息，包括：<br>
1)  设备或软件信息，包括但不限于您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址、位置信息、设备存储和移动设备所用的版本和设备识别码；<br>
2)  在使用我们服务时搜索或浏览的信息，包括但不限于您浏览过的文章、搜索的关键词，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；<br>
3)  有关您曾使用的移动应用（APP）和其他软件的信息，以及您曾经使用该等移动应用和软件的信息。</p>
<p><strong>3、个人信息的使用</strong><br>
(1)我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：<br>
1)  向您提供服务；<br>
2)  在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；<br>
3)  帮助我们设计新服务，改善我们现有服务；<br>
4)  使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；<br>
5)  软件认证或管理软件升级；<br>
6)  让您参与有关我们产品和服务的调查。</p>
<p><strong>(2)为了让您有更好的体验，也为了改善我们的服务，在符合相关法律法规的前提下，我们可能将通过某一项服务收集信息或向第三方提供用户的非个人隐私信息的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能会用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。</strong></p>
<p><strong>(3)个人信息主体注销账户</strong><br>
您随时可注销此前注册的账户，注销申请需求发至此邮箱：service@rich-f.com，我公司会致电用户预留电话对注销需求进行确认。为了维护您的合法权益，您申请注销的用户账户，不存在未了结的权利义务或其他因为注销该账户会产生纠纷的情况，不存在未完结交易。用户账户注销后，双方的权利义务终止。
</p>
<p><strong>4、隐私政策</strong><br>
我们非常重视您的个人信息保护，依据最新的监管要求上线并更新了富融</p>
<p>该政策将帮助您了解以下内容：<br>
1、  我们如何收集和使用您的个人信息<br>
2、  我们如何使用 Cookie<br>
3、  您的个人信息如何存储以及在全球范围转移<br>
4、  我们如何共享、转让、公开披露您的个人信息<br>
5、  我们如何保护您的个人信息<br>
6、  您的权利<br>
7、  未成年人条款<br>
8、  本隐私政策时效及如何更新<br>
9、  如何联系我们<br>
10、其他</p>
<p>本隐私政策适用于「富融」的所有相关服务，请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私政策》，我们会适时对隐私政策进行修订，请以该协议为准。</p></div>
      `
    }
  },
  methods: {
    toGoBack(){
      this.$router.go(-1)
    }
  },
  mounted () {

  },
  created() {
  }
}
</script>


<style lang="scss" scoped>
.newsTitle{
  text-align: left;
  font-size: 18px;
}
.newsTime{
  text-align: center;
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}
.newsContent{
  line-height: 40px;
  font-size: 16px;
  margin-top: 10px;
}
.newsContent p{
  float: left;
  width: 100%;
}
.amountSale{
  color: #f90;
  font-size: 36px;
  float: left;
  text-align: left;
  width: 180px;
}
</style>
